import { Dialog } from '../../Dialog';
import styles from './styles';
import React, {FC, useEffect, useState} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography
} from '@mui/material';
import {CixonTables} from './constants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Add } from '@mui/icons-material';

interface ICellConfigField {
  table?: string;
  field?: string;
  text?: string;
  type: 'table' | 'text';
  valueType?: 'number' | 'date' | 'string';
  isin?: string;
}

interface ICellConfig {
  isLinkWithPdfField?: boolean;
  isDynamic: boolean;
  fields: ICellConfigField[];
  pdfField?: { table?: string, cell?: string };
}

interface ICellConfigProps {
  defaultConfig: string | undefined;
  config: ICellConfig | undefined;
  open: boolean;
  isTitle?: boolean | undefined;
  onClose: () => void;
  onConfirm: (data: any) => void;
}

export const AutomaticCellConfigDialog: FC<ICellConfigProps> = ({
  defaultConfig,
  config,
  open,
  isTitle,
  onClose,
  onConfirm,
}) => {
  const [cellConfig, setCellConfig] = useState<ICellConfig>({
    isDynamic: true,
    fields: [],
  });
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof config === 'object' && config.fields) {
      setCellConfig(config);
    } else {
      setCellConfig({
        isDynamic: true,
        fields: [{ type: 'table', table: defaultConfig, field: '', valueType: 'string' }],
      });
      setSelectedFieldIndex(0);
    }
  }, [config, defaultConfig]);

  const handleLinkWithPdfField = (e) => {
    setCellConfig({
      ...cellConfig,
      isLinkWithPdfField: e.target.checked,
    });
  };

  const handleChangePdfField = (key: string, value) => {
    setCellConfig({
      ...cellConfig,
      pdfField: { ...(cellConfig.pdfField || {}), [key]: value },
    })
  };

  const handleConfirm = () => {
    onConfirm(cellConfig);
  };

  const handleAddField = () => {
    setSelectedFieldIndex(cellConfig.fields.length);
    setCellConfig({
      ...cellConfig,
      fields: [...cellConfig.fields, { type: 'table', table: '', field: '', valueType: 'string' }],
    });
  };

  const handleChangeFieldConfig = (e: any) => {
    setCellConfig({
      ...cellConfig,
      fields: cellConfig.fields.map((field, index) => index === selectedFieldIndex ? {
        ...field,
        [e.target.name]: e.target.value,
      } : field),
    })
  };

  const handleSelectField = (index: number | null) => {
    setSelectedFieldIndex(index);
  };

  const handleDeleteField = () => {
    setCellConfig({
      ...cellConfig,
      fields: cellConfig.fields.filter((_, index) => index !== selectedFieldIndex),
    })
    setSelectedFieldIndex(null);
  };

  const onDragComplete = (result) => {
    if (!result.destination) return;

    const arr = [...cellConfig.fields];

    //Changing the position of Array element
    const removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    //Updating the list
    setCellConfig({
      ...cellConfig,
      fields: arr,
    });
  };

  // useEffect(() => {
    
  // }, [defaultConfig]);

  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={{ width: '100%' }}>
        {!isTitle && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', grid: '8px' }}>
              <FormControlLabel
                label="Is linked with PDF field?"
                sx={styles.switch}
                control={
                  <Switch
                    checked={cellConfig.isLinkWithPdfField}
                    color="secondary"
                    onChange={handleLinkWithPdfField}
                  />
                }
              />

              {cellConfig.isLinkWithPdfField && (
                <Box sx={{ display: 'flex', grid: '4px' }}>
                  <Select sx={{ width: '150px' }} value={cellConfig?.pdfField?.table} onChange={(e) => handleChangePdfField('table', e.target.value)}>
                    <MenuItem value="preise">Preis</MenuItem>
                    <MenuItem value="konditionen">Konditionen</MenuItem>
                  </Select>
                  <Select sx={{ ml: '8px', width: '150px' }} value={cellConfig?.pdfField?.cell} onChange={(e) => handleChangePdfField('cell', e.target.value)}>
                    <MenuItem value="ausschuttung">ausschuttung</MenuItem>
                    <MenuItem value="fondsvolumen">fondsvolumen</MenuItem>
                    <MenuItem value="kursper">kursper</MenuItem>
                    <MenuItem value="letzte">letzte</MenuItem>
                    <MenuItem value="mepreis">mepreis</MenuItem>
                    <MenuItem value="wahrung">wahrung</MenuItem>
                  </Select>
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 8, mt: 16 }}>
          <DragDropContext onDragEnd={onDragComplete}>
            <Droppable droppableId="drag-drop-list" direction="horizontal">
              {(provided, snapshot) => (
                <Box
                  className="drag-drop-list-container"
                  sx={styles.configWrapper}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {cellConfig?.fields.map((field, index) => (
                    <Draggable key={index} draggableId={`${index}_${field.text}`} index={index}>
                      {(provided) => (
                        <Box
                          className="item-card"
                          ref={provided.innerRef}
                          sx={(theme) => styles.configField(
                            theme,
                            { type: field.type, active: selectedFieldIndex === index }
                          )}
                          onClick={() => handleSelectField(index)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Typography variant="subtitle2">
                            {field.type === 'text' ? field.text : `${field.table || '(table)'}.${field.field || '(field)'}`}
                          </Typography>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          {cellConfig.fields.length === 0 && <IconButton sx={{ width: 48, height: 48 }} onClick={handleAddField}><Add /></IconButton>}
        </Box>

        {selectedFieldIndex !== null && (
          <Box sx={styles.fieldOption}>
            {cellConfig.fields[selectedFieldIndex]?.type === 'table' && (
              <>
                <Box sx={styles.fieldOptionForm}>
                  <Typography variant="subtitle2">Table:</Typography>
                  <Select
                    name="table"
                    value={cellConfig.fields[selectedFieldIndex]?.table}
                    onChange={handleChangeFieldConfig}
                  >
                    {CixonTables.map((table) => (
                      <MenuItem value={table.value}>{table.name}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={styles.fieldOptionForm}>
                  <Typography variant="subtitle2">Column:</Typography>
                  <Select
                    name="field"
                    value={cellConfig.fields[selectedFieldIndex]?.field}
                    onChange={handleChangeFieldConfig}
                  >
                    {CixonTables
                      .find((table) => table.value === cellConfig.fields[selectedFieldIndex]?.table)?.fields
                      .map((field) => (
                        <MenuItem value={field}>{field}</MenuItem>
                      ))
                    }
                  </Select>
                </Box>
                <Box sx={styles.fieldOptionForm}>
                  <Typography variant="subtitle2">Value Type:</Typography>
                  <Select
                    name="valueType"
                    label="Value Type"
                    value={cellConfig.fields[selectedFieldIndex]?.valueType || 'string'}
                    onChange={handleChangeFieldConfig}
                  >
                    <MenuItem value="string">String</MenuItem>
                    <MenuItem value="number">Number</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                    <MenuItem value="date">Date</MenuItem>
                  </Select>
                </Box>
              </>
            )}
            {cellConfig.fields[selectedFieldIndex]?.type === 'text' && (
              <Box sx={styles.fieldOptionForm}>
                <Typography variant="subtitle2">Text:</Typography>
                <OutlinedInput
                  name="text"
                  value={cellConfig.fields[selectedFieldIndex]?.text}
                  onChange={handleChangeFieldConfig}
                />
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: '16px' }}>
          <Button variant="outlined" onClick={handleConfirm}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
