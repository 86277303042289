// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  tableBody: () => ({
    display: 'flex',
  }),
  tableHeader: (theme) => ({
    '.MuiTableCell-root': {
      borderBottom: `3px solid ${theme.palette.secondary.main} !important`,
    },
    th: {
      '.MuiButtonBase-root': {
        width: '32px',
        height: '32px',
      },
    },
  }),
  tableRow: (theme) => ({
    border: `1px solid ${theme.palette.secondary.main} !important`,
    borderRadius: '0 !important',
  }),
  tableCell: (theme) => ({
    border: `1px solid ${theme.palette.secondary.main} !important`,
    borderRadius: '0 !important',
    '&:not(th)': {
      '.MuiIconButton-root': {
        width: '32px',
        height: '32px',
        marginLeft: '8px',
        opacity: '0.5',
        '&:hover': {
          opacity: '1',
        },
      },
    },
    '& input': {
      background: 'transparent',
      margin: '10px',
      p: '8px 4px',
      width: 'calc(100% - 28px)',
      minWidth: '100px',
      fontSize: '14px',
      height: '20px',
      border: `0`,
      '&:focus': {
        outline: `1px solid ${theme.palette.secondary.main}`,
        background: 'white',
      },
      '&:disabled': {
        color: theme.palette.gray.light,
      },
    },
  }),
  dataConfigInputWrapper: (theme) => ({
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '32px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    padding: '0 8px',
    gap: theme.spacing(4),
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '6px',
    },

    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
  configWrapper: (theme) => ({
    display: 'flex',
    width: '400px',
    flexGrow: 1,
    gap: theme.spacing(4),
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '6px',
    },

    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
  configField: (theme, { type }) => ({
    color: theme.palette.common.white,
    width: 'fit-content',
    backgroundColor: type === 'text' ? theme.palette.grey['200'] : theme.palette.success['light'],
    padding: theme.spacing(4, 8),
    borderRadius: theme.spacing(8),
    cursor: 'pointer',
    border: '1px solid transparent',
  }),
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: 'white',
    p: theme.spacing(98, 20, 40, 20),
    maxWidth: { xs: 560, md: 560 },
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

// Export styles
export default styles;
