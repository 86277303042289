import {
  Box,
  Button,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Table as MuiTable,
  TableHead as MuiTableHead,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  Avatar,
  TextField,
  Radio,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import styles from './styles';
import React, { ChangeEvent, useRef, useState } from 'react';
import { BorderHorizontal, BorderVertical, Settings, Info, DragIndicator } from '@mui/icons-material';
import { Dialog } from '../../../../../../components/Dialog';
import apis from '../../../../../../apis';
import { CellConfigDialog } from '../../../../../../components/Dialogs/CellConfig';
import theme from '../../../../../../theme';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AutomaticCellConfigDialog } from '../../../../../../components/Dialogs';

type Props = {
  id?: number;
  isin: string;
  content?: any;
  originalContent?: any;
  preview: boolean;
  onChange: (value: any) => void;
};

export const Table = ({ isin, content, originalContent, onChange }: Props) => {
  const [columnSettingIndex, setColumnSettingIndex] = useState<number | null>(null);
  const [selectedPos, setSelectedPos] = useState<number[] | null>();
  const [selectedCellPos, setSelectedCellPos] = useState<[number, number] | null>(null);
  const [openTitleConfigDlg, setOpenTitleConfigDlg] = useState(false);

  const handleAddColumn = () => {
    const header = content.header;
    const body = content.body;
    onChange({
      ...content,
      header: [...header, ''],
      body: body.map((row) => [...row, '']),
      columns: [
        ...content.columns,
        { type: 'text', ...(content.type === 'Automatic' && { showValueConfiguration: true }) },
      ],
    });
  };

  const handleAddRow = () => {
    const body = content.body;
    const header = content.header;
    onChange({ ...content, body: body.concat([header.map(() => '')]) });
  };

  const handleChangeCell = (rowIndex, columnIndex, e, isObject) => {
    const body = content.body;
    onChange({
      ...content,
      body: body.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((column, cIndex) =>
              cIndex === columnIndex ? (!isObject ? e.target.value : { ...column, text: e.target.value }) : column
            )
          : row
      ),
    });
  };

  const handleChangeLink = (rowIndex, columnIndex, e) => {
    const body = content.body;
    onChange({
      ...content,
      body: body.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((column, cIndex) =>
              cIndex === columnIndex
                ? {
                    linkUrl: column?.linkUrl || '',
                    linkText: column?.linkText || '',
                    [e.target.name]: e.target.value,
                  }
                : column
            )
          : row
      ),
    });
  };

  const handleChangeHeader = (columnIndex, e) => {
    onChange({
      ...content,
      header: content.header.map((cell, index) => (index === columnIndex ? e.target.value : cell)),
    });
  };

  const handleToggleHeader = () => {
    onChange({
      ...content,
      showHeader: !content.showHeader,
    });
  };

  const handleRemoveColumn = (columnIndex) => {
    onChange({
      ...content,
      header: content.header.filter((_, index) => index !== columnIndex),
      body: content.body.map((row) => row.filter((_, index) => index !== columnIndex)),
      columns: content.columns.filter((_, index) => index !== columnIndex),
    });
  };

  const handleRemoveRow = (rowIndex) => {
    onChange({
      ...content,
      body: content.body.filter((_, index) => index !== rowIndex),
    });
  };

  const handleOpenColumSetting = (column: number) => {
    setColumnSettingIndex(column + 1);
  };

  const handleCloseColumnSetting = () => {
    setColumnSettingIndex(null);
  };

  const handleChangeColumnSetting = (e) => {
    onChange({
      ...content,
      columns: content.columns.map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1 ? { ...col, type: e.target.value } : col
      ),
    });
  };

  const fileSelector = useRef<any>();

  const handleOpenBrowser = (row, col) => {
    setSelectedPos([row, col]);
    fileSelector?.current?.click();
  };

  const handleUpdateLogo = async (e: any) => {
    if (!selectedPos) {
      return;
    }

    const [rowIndex, columnIndex] = selectedPos;
    if (e.target.files[0]) {
      apis.cdn.uploadImage(e.target.files[0]).then((res) => {
        if (res.status) {
          onChange({
            ...content,
            body: content.body.map((row, rIndex) =>
              rIndex === rowIndex ? row.map((column, cIndex) => (cIndex === columnIndex ? res.fileName : column)) : row
            ),
          });
        }
      });
    }
  };

  const handleChangeAddToolTip = (e) => {
    onChange({
      ...content,
      columns: content.columns.map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1
          ? { ...col, showColumnTooltip: e.target.checked, columnTooltip: '' }
          : col
      ),
    });
  };

  const handleChangeValueConfigOption = (e) => {
    onChange({
      ...content,
      columns: content.columns.map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1
          ? { ...col, showValueConfiguration: e.target.checked }
          : col
      ),
    });
  };

  const handleChangeColumnTooltipText = (e) => {
    onChange({
      ...content,
      columns: content.columns.map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1 ? { ...col, columnTooltip: e.target.value } : col
      ),
    });
  };

  const handleChangeThemeIndex = (index: number) => {
    onChange({
      ...content,
      themeIndex: index,
    });
  };

  const handleChangeType = (e: SelectChangeEvent) => {
    const type = e.target.value;

    if (type === 'Preis') {
      onChange({
        ...content,
        columns: [{ type: 'text' }, { type: 'text' }],
        showHeader: true,
        header: ['Anteilsklasse', 'R'],
        body: [
          ['Kurs per', { text: '$datum$', type: 'date' }],
          ['Währung', 'EUR'],
          ['Ausgabe- / Rücknahmepreis', { text: '$ausgabepreis$/$ruecknahmepreis$', type: 'number' }],
          ['Fondsvolumen', { text: '$fondsvermoegen$ Milo. EUR', type: 'number' }],
          ['Letzte Ausschüttung', '0,15 EUR'],
        ],
        type,
      });
    } else if (type === 'Manual') {
      onChange({
        columns: originalContent.content.columns,
        showHeader: true,
        header: originalContent.content.header,
        body: originalContent.content.body,
        type,
      });
    } else {
      onChange({
        ...content,
        columns: [{ type: 'text', showValueConfiguration: true }],
        showHeader: true,
        header: [''],
        body: [['']],
        type,
      });
    }
  };

  const handleChangeIsin = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...content,
      isin: e.target.value,
    });
  };

  const handleToggleLinkWithPdfField = () => {
    onChange({
      ...content,
      isLinkedWithPdfField: !content.isLinkedWithPdfField,
    });
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...content, title: e.target.value });
  };

  const handleChangeTitleConfig = (config: any) => {
    onChange({ ...content, title: config });
    setOpenTitleConfigDlg(false);
  };

  const handleToggleDynamicTitle = () => {
    onChange({
      ...content,
      title: content.title?.isDynamic
        ? ''
        : {
            isDynamic: true,
            fields: [],
          },
    });
  };

  const handleOpenCellConfigDialog = (row: number, col: number) => {
    setSelectedCellPos([row, col]);
  };

  const handleCloseCellConfigDialog = () => {
    setSelectedCellPos(null);
  };

  const handleSetCellConfig = (data: any) => {
    if (selectedCellPos) {
      if (content.type !== 'Automatic') {
        const body = content.body;
        onChange({
          ...content,
          body: body.map((row, rIndex) =>
            rIndex === selectedCellPos[0]
              ? row.map((column, cIndex) => (cIndex === selectedCellPos[1] ? data : column))
              : row
          ),
        });
      } else {
        const body = content.body;
        const emptyRow: any[] = [];
        onChange({
          ...content,
          body:
            body.length > 0
              ? body.map((row, rIndex) =>
                  rIndex === selectedCellPos[0]
                    ? row.map((column, cIndex) => (cIndex === selectedCellPos[1] ? data : column))
                    : row
                )
              : [[(emptyRow[selectedCellPos[1]] = data)]],
        });
      }
    }
    setSelectedCellPos(null);
  };

  const handleSetPdfTableKey = (e: SelectChangeEvent) => {
    onChange({
      ...content,
      pdfTableKey: e.target.value,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const arr = [...content.body];

    const removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);
    onChange({ ...content, body: arr });
  };

  const handleOpenTitleConfig = () => {
    setOpenTitleConfigDlg(!openTitleConfigDlg);
  };
  console.log(content.body[0].find(item => item.fields && item.fields.length > 0)?.fields[0].table, content.body)
  return (
    <Box>
      <Typography variant="h5">Table</Typography>

      <Box display="flex" alignItems="center" gap={16} mb={16}>
        <Typography variant="subtitle1">Title:</Typography>
        {content.title?.isDynamic ? (
          <>
            <Box sx={styles.dataConfigInputWrapper}>
              {content.title?.fields?.map((field, index) => (
                <Box key={`${index}th`} sx={(theme) => styles.configField(theme, { type: field.type })}>
                  <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                    {field.type === 'text' ? field.text : `${field.table || '(table)'}.${field.field || '(field)'}`}
                  </Typography>
                </Box>
              ))}
            </Box>
            <CellConfigDialog
              isin={content.isin}
              config={content.title}
              open={openTitleConfigDlg}
              onClose={() => setOpenTitleConfigDlg(false)}
              onConfirm={handleChangeTitleConfig}
            />
            <IconButton sx={{ mr: 4, width: 32, height: 32 }} onClick={handleOpenTitleConfig}>
              <Settings />
            </IconButton>
          </>
        ) : (
          <OutlinedInput
            sx={{ width: '300px' }}
            name="title"
            type="string"
            value={content.title || ''}
            onChange={handleChangeTitle}
          />
        )}
        <FormControlLabel
          label="Is dynamic?"
          sx={styles.switch}
          control={<Switch checked={content.title?.isDynamic} color="secondary" onChange={handleToggleDynamicTitle} />}
        />
      </Box>

      <Box display="flex" sx={{ my: 20 }}>
        <Box>
          <Select sx={{ width: '300px' }} value={content?.type || 'Manual'} onChange={handleChangeType}>
            <MenuItem value="Manual">Manual</MenuItem>
            <MenuItem value="Automatic">Automatic</MenuItem>
            <MenuItem value="Axxion">Axxion</MenuItem>
            <MenuItem value="Preis">Preis</MenuItem>
          </Select>
        </Box>

        {content?.type === 'Axxion' && (
          <Box sx={{ ml: '30px' }}>
            <OutlinedInput value={content?.isin || ''} onChange={handleChangeIsin} placeholder="ISIN" />
          </Box>
        )}
      </Box>

      <Box display="flex" sx={{ mb: '8px' }}>
        <FormControlLabel
          label="Is linked with PDF field"
          control={
            <Switch checked={content.isLinkedWithPdfField} color="secondary" onChange={handleToggleLinkWithPdfField} />
          }
        />

        {content.isLinkedWithPdfField && (
          <Select sx={{ width: '300px' }} value={content?.pdfTableKey} onChange={handleSetPdfTableKey}>
            <MenuItem value="risikoindikatoren">RISIKOINDIKATOREN</MenuItem>
            <MenuItem value="wertentwicklung_nach_perioden">WERTENTWICKLUNG NACH PERIODEN</MenuItem>
          </Select>
        )}
      </Box>

      <Button sx={{ marginRight: 10 }} onClick={handleAddColumn}>
        Add Column
      </Button>
      {content.type !== 'Automatic' && (
        <Button sx={{ marginRight: 10 }} onClick={handleAddRow}>
          Add Row
        </Button>
      )}
      <FormControlLabel
        label="Show Header"
        sx={styles.switch}
        control={<Switch checked={content.showHeader} color="secondary" onChange={handleToggleHeader} />}
      />

      <Box display="flex" gap={16} my={8}>
        {[0, 1, 2].map((color, index) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Radio
              color="secondary"
              checked={index === content.themeIndex}
              size="medium"
              onChange={() => handleChangeThemeIndex(index)}
            />
            <Typography variant="subtitle2" sx={{ color }}>
              Table Theme {index}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: 0, flexGrow: 1, overflow: 'auto', border: `1px solid ${theme.palette.secondary.main}` }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`${content.title}-table`}>
              {(provided) => (
                <MuiTable {...provided.droppableProps} ref={provided.innerRef}>
                  <MuiTableHead sx={styles.tableHeader}>
                    <MuiTableRow sx={styles.tableRow}>
                      {content?.header?.map((cell, columnIndex) => (
                        <MuiTableCell sx={styles.tableCell}>
                          <Box display="flex" alignItems="center">
                            <input
                              disabled={!content.showHeader}
                              value={cell}
                              onChange={(e) => handleChangeHeader(columnIndex, e)}
                            />
                            {content.columns && content.columns[columnIndex]?.showColumnTooltip && (
                              <Tooltip title={content.columns[columnIndex].columnTooltip}>
                                <Box display="flex" alignItems="center" mr={8}>
                                  <Info />
                                </Box>
                              </Tooltip>
                            )}
                            <Tooltip title="Column Setting">
                              <IconButton
                                sx={{ mr: 4 }}
                                onClick={() =>
                                  handleOpenColumSetting(columnIndex)
                                }
                              >
                                <Settings />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove Column">
                              <IconButton onClick={() => handleRemoveColumn(columnIndex)}>
                                <BorderVertical />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </MuiTableCell>
                      ))}
                    </MuiTableRow>
                  </MuiTableHead>
                  <MuiTableBody>
                    {content?.body?.map((row, rowIndex) => (
                      <Draggable key={row.id} draggableId={`${content.title}-row-${rowIndex}`} index={rowIndex}>
                        {(provided, snapshot) => (
                          <MuiTableRow
                            sx={styles.tableRow}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              background: snapshot.isDragging ? '#ecb6d680' : 'white',
                              ...(snapshot.isDragging && { display: 'flex' }),
                              ...provided.draggableProps.style,
                            }}
                          >
                            {row.map((cell, columnIndex) => (
                              <MuiTableCell
                                sx={styles.tableCell}
                                style={{ ...(snapshot.isDragging && { flex: 1, height: '100%' }) }}
                              >
                                <Box width="100%" display="flex" alignItems="center">
                                  <Box
                                    sx={styles.dragIndicator}
                                    className="drag-handle"
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIndicator />
                                  </Box>
                                  {content.columns &&
                                  content.columns[columnIndex]?.type === 'text' &&
                                  content.columns[columnIndex].showValueConfiguration ? (
                                    <Box sx={styles.configWrapper}>
                                      {cell.fields?.map((field, index) => (
                                        <Box
                                          key={index}
                                          sx={(theme) => styles.configField(theme, { type: field.type })}
                                        >
                                          <Typography variant="subtitle2">
                                            {field.type === 'text'
                                              ? field.text
                                              : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                          </Typography>
                                        </Box>
                                      ))}
                                    </Box>
                                  ) : (
                                    <input
                                      placeholder="Text"
                                      value={typeof cell === 'string' ? cell : cell.text}
                                      onChange={(e) =>
                                        handleChangeCell(rowIndex, columnIndex, e, typeof cell !== 'string')
                                      }
                                    />
                                  )}
                                  {content.columns && content.columns[columnIndex]?.type === 'link' && (
                                    <>
                                      <input
                                        placeholder="Text"
                                        name="linkText"
                                        value={cell?.linkText}
                                        onChange={(e) => handleChangeLink(rowIndex, columnIndex, e)}
                                      />
                                      <input
                                        placeholder="URL"
                                        name="linkUrl"
                                        value={cell?.linkUrl}
                                        onChange={(e) => handleChangeLink(rowIndex, columnIndex, e)}
                                      />
                                    </>
                                  )}
                                  {content.columns && content.columns[columnIndex]?.type === 'avatar' && (
                                    <>
                                      {cell && <Avatar sx={{ mr: 10 }} src={cell} />}
                                      <Button size="small" onClick={() => handleOpenBrowser(rowIndex, columnIndex)}>
                                        {cell ? 'Change' : 'Upload'}
                                      </Button>
                                      <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        ref={fileSelector}
                                        onChange={handleUpdateLogo}
                                      />
                                    </>
                                  )}
                                  <Box display="flex" sx={{ ml: 'auto' }}>
                                    {content.columns[columnIndex]?.showValueConfiguration && (
                                        <Tooltip title="Cell Config">
                                          <IconButton onClick={() => handleOpenCellConfigDialog(rowIndex, columnIndex)}>
                                            <Settings />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    {columnIndex === 0 && (
                                      <Tooltip title="Remove Row">
                                        <IconButton onClick={() => handleRemoveRow(rowIndex)}>
                                          <BorderHorizontal />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Box>
                              </MuiTableCell>
                            ))}
                          </MuiTableRow>
                        )}
                      </Draggable>
                    ))}
                  </MuiTableBody>
                </MuiTable>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>

      {!!columnSettingIndex && (
        <Dialog open={!!columnSettingIndex} onClose={handleCloseColumnSetting} sx={styles.dialog}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ padding: '0 20px' }}>
              <Box display="flex" alignItems="center">
                <Typography sx={{ mr: 10 }}>Type:</Typography>
                <Select
                  sx={{ flexGrow: 1 }}
                  value={content.columns && content.columns[columnSettingIndex - 1]?.type}
                  onChange={handleChangeColumnSetting}
                >
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="avatar">Avatar</MenuItem>
                  <MenuItem value="link">Link</MenuItem>
                </Select>
              </Box>
              <Box sx={{ mt: 10, display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  label="Show value configuration"
                  sx={{ width: 'fit-content' }}
                  control={
                    <Switch
                      disabled={content.type === 'Automatic'}
                      checked={content.columns && content.columns[columnSettingIndex - 1]?.showValueConfiguration}
                      color="secondary"
                      onChange={handleChangeValueConfigOption}
                    />
                  }
                />
              </Box>
              <Box sx={{ mt: 10, display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  label="Show Column Tooltip"
                  sx={{ width: 'fit-content' }}
                  control={
                    <Switch
                      checked={content.columns && content.columns[columnSettingIndex - 1]?.showColumnTooltip}
                      color="secondary"
                      onChange={handleChangeAddToolTip}
                    />
                  }
                />
                {content.columns && content.columns[columnSettingIndex - 1]?.showColumnTooltip && (
                  <TextField
                    label="Tooltip Text"
                    color="secondary"
                    multiline
                    value={content.columns[columnSettingIndex - 1]?.columnTooltip}
                    onChange={handleChangeColumnTooltipText}
                  />
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleCloseColumnSetting}>Okay</Button>
            </Box>
          </Box>
        </Dialog>
      )}

      {selectedCellPos && (
        content.type !== 'Automatic' ? (
          <CellConfigDialog
            isin={isin}
            config={content.type !== 'Automatic' ? content?.body[selectedCellPos[0]][selectedCellPos[1]] : undefined}
            open={!!selectedCellPos}
            onClose={handleCloseCellConfigDialog}
            onConfirm={handleSetCellConfig}
          /> 
        ) : (
          <AutomaticCellConfigDialog
            defaultConfig={content.body[0]?.find(item => item.fields && item.fields.length > 0)?.fields[0].table}
            config={content?.body[selectedCellPos[0]][selectedCellPos[1]]}
            open={!!selectedCellPos}
            onClose={handleCloseCellConfigDialog}
            onConfirm={handleSetCellConfig}
          />
        ))}
    </Box>
  );
};
