import React, { ChangeEvent, FC, useState, useRef } from 'react';
import styles from './styles';
import { Box, Button, FormControlLabel, IconButton, Switch, Tabs, Typography } from '@mui/material';
import { ContentWidget } from './components';
import { PlusIcon } from '../../../../assets/icons';
import {Check, Close, Edit, Delete } from '@mui/icons-material';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

type ITab = {
  id: number;
  label: string;
  content?: any;
};

interface IWidgetProps {
  isin?: string;
  widget?: any;
  originalWidget?: any;
  preview?: boolean;
  themeColor?: string;
  onChangeWidget?: (widget) => void;
}

const Widget: FC<IWidgetProps> = ({ isin, widget, originalWidget, preview, themeColor, onChangeWidget }) => {

  // When widget has tabs
  const [tabs, setTabs] = useState<ITab[]>(widget?.tabs?.length ? widget.tabs : []);

  // When widget has content without tabs
  const [widgetContent, setWidgetContent] = useState<any>(widget?.contents || []);

  const [newTab, setNewTab] = useState<ITab | null>(null);
  const [editingTab, setEditingTab] = useState<boolean>(false);
  const [editingTabTitle, setEditingTabTitle] = useState<string>('');
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [mode, setMode] = useState<string>(widget?.mode || 'tab');

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeActiveTab = (_, value) => {
    setActiveTab(value);
  };

  const handleAddNewTab = () => {
    setNewTab({ id: +new Date(), label: '', content: [] });
    setActiveTab(null);
  };

  const handleChangeNewTab = (e: ChangeEvent<HTMLInputElement>) => {
    if (newTab) {
      setNewTab({ ...newTab, label: e.target.value });
    }
  };

  const handleConfirmAddTab = () => {
    if (newTab && onChangeWidget) {
      const updatedTabs = [ ...tabs, newTab];
      setTabs(updatedTabs);
      onChangeWidget({
        ...widget,
        tabs: updatedTabs,
        mode
      });
      setNewTab(null);
    }
  };

  const handleCancelAddNewTab = () => {
    setNewTab(null);
  };

  const handleChangeTabContent = (id: number, content: any) => {
    if (onChangeWidget) {
      const updatedTabs = tabs.map((tab) => tab.id === id ? { ...tab, content } : tab);
      setTabs(updatedTabs);
      setWidgetContent(content);
      onChangeWidget({
        ...widget,
        tabs: updatedTabs,
        contents: content,
        mode: mode
      });
    }
  };

  const handleRemoveTab = () => {
    const updatedTabs = tabs.filter((tab) => tab.id !== activeTab);
    setTabs(updatedTabs);
    if (onChangeWidget) {
      onChangeWidget({
        ...widget,
        contents: null,
        tabs: updatedTabs,
        mode,
      });
      setActiveTab(null);
    }
  };

  const handleEditTabTitle = () => {
    if (editingTab) {
      const updatedTabs = tabs.map((tab) => tab.id === activeTab ? { ...tab, label: editingTabTitle } : tab);
      setTabs(updatedTabs);
      if (onChangeWidget) {
        onChangeWidget({
          ...widget,
          tabs: updatedTabs,
          contents: null,
          mode,
        });
      }
    } else {
      setEditingTabTitle(tabs.find((tab) => tab.id === activeTab)?.label || "");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    setEditingTab(!editingTab);
  };

  const handleChangeTabTitle = (e) => {
    setEditingTabTitle(e.target.value);
  };

  const handleCancelEditing = () => {
    setEditingTab(false);
    setEditingTabTitle("");
  };

  const onChangeViewMode = () => {
    if (onChangeWidget) {
      const updatedMode = mode === 'tab' ? 'single' : 'tab';
      setMode(updatedMode);
      onChangeWidget({
        ...widget,
        mode: updatedMode
      });
    }
  }

  const onDragComplete = (result) => {
    if (!result.destination) return;

    const arr = [...tabs];

    const removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);
    setTabs(arr);
    onChangeWidget && onChangeWidget({
      ...widget,
      tabs: arr,
    });
  };

  return (
    <Box sx={styles.widgetWrapper}>
      <Box sx={styles.widgetContainer}>
        {mode === 'tab' ? (
          <>
            <Tabs sx={(theme) => styles.tabBar(theme, themeColor)} variant='scrollable' value={activeTab} onChange={handleChangeActiveTab}>
              <DragDropContext onDragEnd={onDragComplete}>
                <Droppable droppableId="tabs-list" direction="horizontal">
                  {(provided) => (
                    <Box sx={{ display: "flex" }} ref={provided.innerRef} {...provided.droppableProps}>
                      {tabs?.map((tab, index) => (
                        <Draggable
                          key={tab.id}
                          draggableId={tab.id.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              sx={{ height: '100%' }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={index}
                            >
                              <Box
                                sx={(theme) => editingTab
                                  ? styles.editTab(theme, { color: themeColor })
                                  : styles.draggableTab(theme, {
                                    active: tab.id === activeTab,
                                    color: themeColor,
                                  }
                                )}
                                onClick={() => { setActiveTab(tab.id) }}
                              >
                                <Box display="flex" alignItems="center" sx={(editingTab && activeTab === tab.id) ? styles.editTabInput : styles.tabInput}>
                                  <Typography>{!(editingTab && activeTab === tab.id) && tab.label}</Typography>
                                  <input hidden={!(editingTab && activeTab === tab.id)} placeholder='Tab title' value={editingTabTitle} ref={inputRef} onChange={handleChangeTabTitle} />
                                  {activeTab === tab.id && (
                                    <IconButton
                                      sx={(theme) => styles.tabControlIcon(theme)}
                                      onClick={handleEditTabTitle}
                                    >
                                      {editingTab ? (<Check />) : (<Edit />)}
                                    </IconButton>
                                  )}
                                  {editingTab && activeTab === tab.id && (
                                    <IconButton
                                      sx={(theme) => styles.tabControlIcon(theme)}
                                      onClick={handleCancelEditing}
                                    >
                                      <Close />
                                    </IconButton>
                                  )}
                                  {!editingTab && activeTab === tab.id && (
                                    <IconButton
                                      sx={(theme) => styles.tabControlIcon(theme)}
                                      onClick={handleRemoveTab}
                                    >
                                      <Delete />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
              {newTab && (
                <Box sx={styles.newTabInput}>
                  <input placeholder='New Tab' value={newTab.label} onChange={handleChangeNewTab} />
                  <IconButton disabled={!newTab.label} sx={styles.newTabIcon} onClick={handleConfirmAddTab}><Check /></IconButton>
                  <IconButton sx={styles.newTabIcon} onClick={handleCancelAddNewTab}><Close /></IconButton>
                </Box>
              )}
              <Button sx={styles.addNewTab} disabled={!!newTab} variant="text" onClick={handleAddNewTab} startIcon={<PlusIcon />}>
                Add
              </Button>
              <FormControlLabel
                label={mode === 'tab' ? 'Tab View' : 'Single View'}
                sx={styles.switch}
                control={
                  <Switch
                    checked={mode === 'tab'}
                    color="secondary"
                    onChange={onChangeViewMode}
                  />
                }
              />
            </Tabs>
            <Box sx={styles.tabContent}>
              {activeTab ? (
                <ContentWidget
                  id={activeTab}
                  isin={isin}
                  preview={preview}
                  isTop
                  type="container"
                  themeColor={themeColor}
                  content={tabs?.find((tab) => tab.id === activeTab)?.content}
                  originalContent={originalWidget?.tabs?.find(item => item.id === activeTab)?.content}
                  onChange={handleChangeTabContent}
                  onDelete={undefined}
                />
              ) : (
                <Typography color="lightgray">No Content</Typography>
              )}
            </Box>
          </>
        ) : (
          <Box>
            <Box sx={(theme) => styles.contentHeader(theme, themeColor)}>
              <Typography>Widget Content</Typography>
              <FormControlLabel
                label={mode === 'tab' ? 'Tab View' : 'Single View'}
                sx={styles.switch}
                control={
                  <Switch
                    checked={mode === 'tab'}
                    color="secondary"
                    onChange={onChangeViewMode}
                  />
                }
              />
            </Box>
            <Box sx={styles.tabContent}>
              <ContentWidget
                id={0}
                isin={isin}
                preview={preview}
                type="container"
                // originalContent={or}
                content={widgetContent}
                themeColor={themeColor}
                onChange={handleChangeTabContent}
                onDelete={undefined}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
};

export default Widget;
