import React from 'react';
import { useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { PlusIcon } from '../../../../../assets/icons';
import { WidgetListDialog } from '../../../../../components/Dialogs/WidgetList';
import styles from './styles';
import { PieChartContent } from './PieChart';
import { Collapse } from './Collapse';
import { TextRow } from './Row';
import { Delete, DragIndicator } from '@mui/icons-material';
import { BarChartContent } from './BarChart';
import { HistoryChart } from './HistoryChart';
import { DocumentList } from './DocumentList';
import { RichText } from './RichText/RichText';
import { Table } from './Table';
import { AmChart } from './AmChart';
import { Blocks } from './Blocks';
import {useAlertContext} from '../../../../../components/Alert/alertContext';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export type WidgetProps = {
  id: number;
  isin?: string;
  type: string;
  content: any;
  originalContent?: any;
  preview: boolean;
  isTop?: boolean;
  themeColor?: string;
  onChange: (id: number | undefined, contentData: any) => void;
  onDelete?: (id: number) => void;
}

export const ContentWidget = ({
  id,
  isin,
  type,
  isTop,
  content,
  originalContent,
  preview,
  themeColor,
  onChange,
  onDelete,
}: any) => {
  const { activeAlert } = useAlertContext();
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSelect = (selected) => {
    onChange(id, [...content, selected]);
  };

  const buildContent = (widget: WidgetProps) => {
    switch (widget.type) {
      case 'container': {
        const handleChangeValue = (widgetId, newContentData) => {
          onChange(widget.id, [...content.map((w) => w.id === widgetId ? { ...w, content: newContentData } : w)]);
        };

        const handleDeleteWidget = (widgetId) => {
          activeAlert({
            type: 'info',
            text: "Are you sure to remove this widget?",
          }, () => {
            onChange(widget.id, [...(content?.filter((item) => item.id !== widgetId) || [])]);
          });
        };

        const onDragComplete = (result) => {
          if (!result.destination) return;

          const arr = [...content];

          const removedItem = arr.splice(result.source.index, 1)[0];
          arr.splice(result.destination.index, 0, removedItem);
          onChange(widget.id, arr);
        };

        // @ts-ignore
        return (
          <>
            {isTop ? (
              <Box>
                <DragDropContext onDragEnd={onDragComplete}>
                  <Droppable droppableId="content-widgets">
                    {(provided, snapshot) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {content?.map((w, index) => (
                          <Draggable
                            key={w.id}
                            draggableId={w.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                sx={{ position: 'relative' }}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <ContentWidget {...w} originalContent={originalContent[index]} isin={isin} themeColor={themeColor} preview={preview} onChange={handleChangeValue} onDelete={handleDeleteWidget} />
                                <Box sx={styles.dragIndicator} className="drag-handle" {...provided.dragHandleProps}>
                                  <DragIndicator />
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            ) : content?.map((w, index) => (
              <React.Fragment key={index}>
                <ContentWidget {...w} originalContent={originalContent[index]} isin={isin} themeColor={themeColor} preview={preview} onChange={handleChangeValue} onDelete={handleDeleteWidget} />
              </React.Fragment>
            ))}
            {!preview && (
              <Button variant="outlined" sx={styles.widgetButton} startIcon={<PlusIcon />} onClick={handleOpenDialog}>
                Add
              </Button>
            )}

            {//@ts-ignore
              !preview && !!onDelete && (<Button variant="outlined" sx={styles.widgetButton} startIcon={<Delete />} onClick={onDelete && onDelete(widget.id)}>
                Delete
              </Button>
            )}

            {openDialog && (
              <WidgetListDialog
                open={openDialog}
                onSelect={handleSelect}
                onClose={handleCloseDialog}
              />
            )}
          </>
        );
      }

      case 'collapse': {
        const handleChange = (id, contentValue) => {
          onChange(id, contentValue);
        };

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <Collapse id={id} preview={preview} themeColor={themeColor} originalContent={originalContent.content} content={content} onChange={handleChange} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'pie-chart': {
        const handleChange = (id, contentValue) => {
          onChange(id, contentValue);
        };
        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <PieChartContent id={widget?.id} preview={preview} content={widget.content} onChange={handleChange} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'block': {
        const handleChange = (id, contentValue) => {
          onChange(id, contentValue);
        };
        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <Blocks id={widget?.id} preview={preview} content={widget.content} onChange={handleChange} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'bar-chart': {
        const handleChange = (id, contentValue) => {
          onChange(id, contentValue);
        };
        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <BarChartContent id={widget?.id} preview={preview} content={widget.content} onChange={handleChange} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'text-row': {
        const handleChange = (contentValue) => {
          onChange(widget?.id, contentValue);
        };

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <TextRow id={widget?.id} preview={preview} content={widget.content} onChange={handleChange} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'history': {
        const handleChangeText = (text: string) => {
          onChange(widget?.id, { ...content, text });
        };

        const handleChangeTheme = (theme: string) => {
          onChange(widget?.id, { ...content, theme });
        };

        const handleChangeChartType = (type: string) => {
          onChange(widget?.id, { ...content, chartType: type });
        };

        const handleChangeChartIsin = (isin: string) => {
          onChange(widget?.id, { ...content, isin });
        };

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <HistoryChart
              id={widget?.id}
              isin={isin as string}
              content={content}
              themeColor={themeColor}
              onChange={onChange}
              onChangeDescription={handleChangeText}
              onChangeTheme={handleChangeTheme}
              onChangeChartType={handleChangeChartType}
              onChangeChartIsin={handleChangeChartIsin}
            />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'document-list': {
        const handleChangeDocumentList = (id, content) => {
          onChange(id, content);
        };

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <DocumentList id={widget?.id} content={widget.content} onChange={handleChangeDocumentList} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'rich-text': {
        const handleChangeRichText = (e) => {
          onChange(widget?.id, e);
        }

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <RichText content={widget.content} onChange={handleChangeRichText} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'table': {
        const handleChangeTable = (value) => {
          onChange(widget?.id, value ? value : widget.content);
        };

        return (
          <Box sx={(theme) => styles.widget(theme, { preview })}>
            <Table isin={isin as string} preview={preview} content={widget.content} originalContent={originalContent} onChange={handleChangeTable} />
            <IconButton sx={styles.remove} onClick={() => onDelete && onDelete(widget.id)}>
              <Delete />
            </IconButton>
          </Box>
        )
      }

      case 'amchart': {
        return (
          <Box>
            <AmChart />
          </Box>
        )
      }

      default:
        return (
          <></>
        )
    }
  };

  return buildContent({
    id,
    isin,
    type,
    preview,
    content,
    originalContent,
    onChange
  });
};
